// https://stackoverflow.com/questions/2450954/how-to-randomize-shuffle-a-javascript-array
export function knuth_shuffle(array) {
    const arrayCopy = [...array];
    let currentIndex = array.length;
    while (0 !== currentIndex) {
        const randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;
        const temporaryValue = arrayCopy[currentIndex];
        arrayCopy[currentIndex] = arrayCopy[randomIndex];
        arrayCopy[randomIndex] = temporaryValue;
    }
    return arrayCopy;
}
