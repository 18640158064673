import React from "react"
import {Logo} from './Logo';

export const Landing = ({ colors }) => {
    const handleClick = () => {
        window.location.href='https://www.behance.net/tevincocin';
    };

    return (
        <Logo onClick={handleClick}>
            <svg x="0px" y="0px" viewBox="0 0 320 200" width="50%">
                <g id="logotipo">
                    <g style={{fill: colors[1]}}>
                        <path className="st0" d="M7.73,0c1.4,0.02,3.63,2.07,4.93,2.14s2.65,0.55,4.74,0.58s4.42,1.52,5.15,1.64
                        c0.74,0.12,5.39,0.15,6.06,0.15s5.77,0.6,5.77,0.6s2.76,0.22,3.68,0.17c0.92-0.05,3.51-0.17,4.18-0.17c0.67,0,2.87,0.1,3.41,0.09
                        c0.53-0.02,2.65-1.87,3.83-2.09s8.18-0.03,9.41,0.19s1.66,0.43,2.55,0.39s3.35-0.43,4.04-0.5c0.68-0.07,10.32-1.03,14.1-0.98
                        s52.53,6.44,69.1,13.15c16.56,6.71,17.93,7.12,26.42,8.63s16.02,0.96,18.76,1.1c2.74,0.14,10.4,1.1,14.24,1.1
                        c3.83,0,25.74-1.51,39.97-4.38c14.24-2.88,29.43-5.48,33.68-5.75c4.24-0.27,12.05,0.96,13.55,1.23c1.51,0.27,6.16-0.14,7.8,0.41
                        c1.64,0.55,4.15,1.42,6.8,2.56c0.32-1.51,1.57-4.42,3.1-5.07c1.41-0.59,2.1-0.27,3.06,0.09c0.96,0.37,3.05,1.55,3.79,3.7
                        c0.73,2.15-1.37,9.72-0.96,10.86s0,2.88-0.09,3.38s-2.66,7.11-3.79,8.24s-2.05,8.79-2.24,10.16c-0.18,1.37-0.09,4.66-1.19,7.49
                        s-5.66,14.79-6.85,17.62s-2.92,11.59-4.75,14.15s-9.31,5.66-15.97,8.76s-13.14,12.69-17.61,15.34s-13.96,7.21-15.42,7.94
                        c-1.46,0.73-18.89,10.59-26.83,12.32s-12.96,1.82-15.61,2.37c-2.65,0.55-24.18,5.48-44.26,1.55s-29.66-5.66-43.26-9.86
                        s-12.78-8.95-19.17-12.51s-25.55-0.73-34.77-2.37s-21.17-8.49-25.83-12.6s-10.5-15.61-14.24-19.35S12.77,69.51,12.32,67.41
                        c-0.46-2.1-2.56-14.33-3.92-17.34c-1.37-3.01-8.31-17.44-8.4-23c-0.09-5.57,1.19-7.94,1.19-10.22S0,4.62,2.19,2.34
                        C4.37,0.04,7.73,0,7.73,0"/>
                    </g>
                    <g style={{fill: colors[2]}}>
                        <path d="M8.39,27.7c1.1,0.55,2.1,0.55,2.83,1.92s-0.91,5.84-0.46,8.03c0.46,2.19,5.38,9.04,5.48,11.32
                        c0.09,2.28,1.55,13.05,3.65,16.16c2.1,3.1,11.32,9.31,13.05,11.32s8.21,10.95,10.95,14.79c2.74,3.83,5.29,5.2,8.67,7.58
                        c3.38,2.37,11.13,4.75,11.13,4.75c-0.73-0.09,0.09-1-0.18-2.1s-0.64-0.46-1.19-1.1s0.37-1,0.09-1.28c-0.27-0.27-1,0.55-0.82-0.64
                        s2.46-7.85,2.83-10.59s0.73-11.68,0.82-13.42c0.09-1.73,0.27-2.28,0.91-4.47s2.37-8.22,3.19-9.77s-0.36-1.46-0.36-1.46
                        c-2.01-0.73-25.01-12.96-28.66-14.97S18.96,28.8,17.69,27.34c-1.28-1.46-6.21-6.75-6.75-7.85c-0.55-1.1-1.46-1.1-2.19-0.73
                        s-0.37,2.01-0.73,1.28C8.02,20.03,7.29,27.15,8.39,27.7"/>
                        <path d="M77.34,63.71c0,0.75-2.88,9.24-3.22,11.71c-0.34,2.46-0.21,8.35-0.27,10.2c-0.07,1.85-0.75,3.77-1.16,5.68
                        c-0.41,1.92-1.92,5.34-1.92,5.34c1.1,1.3,1.71,1.85,1.23,3.63c-0.48,1.78-4.04,2.05-3.15,3.22s1.57,0.34,1.92,1.16
                        c0.34,0.82-0.62,0.48-0.07,1.1c0.55,0.62,6.71,0.62,9.03,0.96c2.33,0.34,15.54,0.27,18.07,0.41c2.53,0.14,7.6,1.23,8.62,1.64
                        c1.03,0.41,5.41,2.88,6.43,3.15c1.03,0.27,3.7-0.41,4.79-0.55c1.1-0.14,2.26-0.62,2.81-0.21s0.75-0.14,0.48-0.75
                        c-0.27-0.62,0.07-7.05,0.07-7.53s0.48,0.07,0.41-0.48s0.58-4.18,0.62-4.54c0.03-0.36,0.27-1.88-0.07-2.43s-1.11-4.4-1.23-5.12
                        s-0.17-5.08-0.15-5.22s0.09-0.46-0.53-0.51c-0.62-0.05-18.21-8.27-21.56-9.91S78.57,63.64,78.4,63.29
                        C78.3,63.09,77.34,62.96,77.34,63.71"/>
                        <path d="M130.25,90.82c0,0.68,1.16,2.33,1.3,4.86s-0.89,6.37-1.16,8.56s0,5.96-0.21,6.64s-1.03,2.46-1.03,2.46
                        s1.1,0.14,1.64,0.48c0.55,0.34,0.62,0.41,1.37,1.1c0.75,0.68,4.18,1.64,5.27,2.67c1.1,1.03,1.78,1.44,2.53,1.99
                        s1.23,0.2,3.22,1.16c1.98,0.96,4.52,0.96,5.75,1.3s1.37,0.68,2.46,1.1c1.1,0.41,3.49,1.1,11.23,0.82
                        c7.73-0.27,9.79-2.26,10.27-2.74c0.48-0.48,0.41-1.92,0.55-2.88s-0.27-2.33-0.27-2.94c0-0.62-0.48-0.96-0.41-1.64
                        c0.07-0.68,0-0.55,0.41-1.16c0.41-0.62,0.75-3.9,1.03-7.8c0.27-3.9-0.82-9.04-1.1-10.2c-0.27-1.16,0.21-0.82-0.21-1.57
                        c-0.41-0.75-2.26-0.27-2.94,0c-0.68,0.27-11.77,0.34-13.07,0.41s-3.9,0.34-5.54,0.27s-3.15-0.27-7.6-0.62
                        c-4.45-0.34-13.35-3.15-13.35-3.15S130.25,90.14,130.25,90.82"/>
                        <path d="M182.15,92.24c0,0,2.01,6.85,1.64,11.23s-0.27,7.76-0.91,9.4s0.64,2.74,0.37,4.38s0.18,2.56-0.73,4.11
                        c0,0,1.46-0.28,2.1-0.37s5.57,0.18,7.58-0.09s3.92,0.46,5.75,0.09c1.83-0.36,11.41-3.38,13.69-4.11s4.29-0.27,6.85-1.92
                        c0,0-0.36-4.11-0.64-5.48c-0.27-1.37-0.41-2.87-0.5-3.79c-0.09-0.91-1.46-12.83-1.28-13.33c0.18-0.5-0.27-1.05-0.96-1.1
                        c-0.68-0.05-15.33-0.41-16.98-0.32C196.48,91.05,183.65,91.23,182.15,92.24"/>
                        <path d="M226.27,98.9c0.07,1.44,0.89,6.85,0.89,7.39c0,0.55,0.62,2.94,0.82,3.97c0.21,1.03,0,3.36,0,3.36
                        c0.96-0.21,2.67,0.07,4.86,0.89s2.81,0.89,3.83,1.51c1.03,0.62,3.83,0.07,4.65,0c0.82-0.07,1.57,0.14,2.6-0.14
                        s13.55-6.92,14.51-7.26c0.96-0.34,1.03-0.21,2.05-0.89c1.03-0.68,14.31-13.08,15.2-13.76s3.56-2.19,3.56-2.19
                        c-0.48-0.48-0.96-1.37-1.23-1.37c-0.27,0-0.27,0.68-0.41,0.21c-0.14-0.48-1.1-2.12-1.3-4.45c-0.21-2.33-0.55-3.22-1.23-4.79
                        c-0.68-1.57-4.11-5.34-5-6.92c-0.89-1.58-0.21-2.47-0.75-2.4c-0.55,0.07-1.51,0.96-2.46,1.37c-0.96,0.41-4.45,1.71-5.48,2.12
                        s-4.24,1.71-5.61,2.67c-1.37,0.96-3.56,2.33-5.27,3.22s-8.35,3.83-10.06,4.79c-1.71,0.96-4.38,2.53-6.23,3.15
                        s-8.13,1.54-8.56,2.33C225.25,92.46,226.21,97.46,226.27,98.9"/>
                        <path d="M289.66,87.19c1.23-0.34,2.6-1.51,3.01-2.12c0.41-0.62,0.07-0.82,0.14-1.64s0.55-1.57,1.03-2.4
                        c0.48-0.82,0.41-2.94,0.96-4.52c0.55-1.57,0.82-1.99,1.44-3.83c0.62-1.85,1.85-6.02,3.42-9.31c1.57-3.29,1.78-4.18,3.29-8.22
                        s0.96-6.3,1.3-7.33s1.03-3.97,1.1-4.52s0-2.26,0.27-2.53s-0.21-0.96-0.55-0.41s-1.44,2.67-2.67,3.97
                        c-1.23,1.3-3.77,4.38-6.02,6.71s-3.22,4.45-4.18,5.2s-0.48,0.55-3.01,2.81c-2.53,2.26-5.82,4.38-7.32,5.13
                        c-1.51,0.75-2.33,1.78-2.88,2.33c-0.55,0.55-0.14,1.03-0.14,1.03s0.55,3.01,0.96,4.18c0.41,1.16,3.08,3.77,4.72,6.78
                        s1.71,5.89,1.85,6.5c0.14,0.62,0.62,2.46,0.62,2.46C287.67,87.88,288.43,87.53,289.66,87.19"/>
                        <path   d="M272.09,41.5c0.14,0.59,1.05,3.72,1.35,4.75c0.3,1.03,2.81,5.5,3.1,6c0.3,0.5,0.57,1.62,0.66,2.21
                        s0.27,0.5,0.59,0.3c0.32-0.21,1.67-1.03,4.08-2.56c2.42-1.53,6.71-7.35,7.94-8.88c1.23-1.53,5.73-6.09,6.25-6.66
                        s0.91-2.76,1.14-3.01s0.05-0.09,0.64-0.43c0.59-0.34,0.96-1.69,1.26-1.55c0.3,0.14,1.41,0.27,1.66,0.09s-0.16-0.34-0.07-0.52
                        s0.25-0.25,0.98-0.64s0.85-0.48,0.85-0.89s-0.3-0.5-0.57-0.55s-0.14,0.34-1.03,0.48c-0.89,0.14-5.86-0.57-7.03-0.57
                        c-1.16,0-1.3-0.27-2.46-0.41s-1.92,0.55-3.15,0.55c-1.23,0-1.51-0.55-2.88-0.14s-4.11,2.05-6.23,3.22
                        c-2.12,1.16-5.61,3.56-6.5,3.77s-1.3,0.34-1.3,0.34C271.47,37.94,271.95,40.91,272.09,41.5"/>
                        <path   d="M230.66,79.87c0.75-0.21,0.82-0.27,3.08-1.1c2.26-0.82,4.04-2.12,4.65-2.4c0.62-0.27,1.03-0.69,3.56-1.44
                        s12.18-7.33,12.94-7.39c0.75-0.07,3.56-1.78,5.82-2.19s7.19-4.31,7.19-4.31s-0.21-3.35-0.68-5.41c-0.48-2.05-1.3-2.46-2.12-4.72
                        c-0.82-2.26-3.77-13.97-3.77-13.97c-0.82-0.21-2.81,0-3.83,0.07c-1.03,0.07-3.76,0.55-4.45,0.96c-0.68,0.41-2.46,1.57-3.56,2.12
                        c-1.1,0.55-14.24,5.48-15.67,5.89c-1.44,0.41-2.19,0.07-3.97,0.55s-3.97,2.12-4.52,1.99c-0.55-0.14-1.23-0.14-1.23-0.14
                        c-0.41,1.64,0.62,7.12,0.27,8.08c-0.34,0.96-0.27,9.24-0.41,10.75s0.75,14.38,0.75,14.38C226.34,81.78,229.9,80.07,230.66,79.87"
                        />
                        <path   d="M183.97,55.01c0.21,2.33-2.46,13.21-3.08,14.79c-0.62,1.57-1.1,6.64-0.96,7.6s0.51,5.61,0.51,5.61
                        c1.95-0.34,12.97-0.75,17.45-1.03c4.48-0.27,17.21-0.1,17.21-0.1c0-7.36-1.13-11.74-0.96-16.4s0.55-14.14,0.65-15.27
                        c0.1-1.13-0.24-1.71-0.24-1.71c-0.51-0.51-0.99-0.45-1.51-0.51c-0.51-0.07-1.92-0.89-2.5-1.3s-0.62-0.89-1.57-1.47
                        c-0.96-0.58-0.79-0.17-3.22-1.23c-2.43-1.06-2.22-1.57-3.15-1.78c-0.92-0.21-1.99-0.27-2.91-0.48s-1.03-0.72-3.08-1.1
                        s-3.22,0-4.38-0.17s-2.67-0.38-3.7-0.45s-1.37,0.41-2.67,0.55s-3.76,0.48-3.76,0.48C181.78,44.26,183.77,52.69,183.97,55.01"/>
                        <path   d="M132.03,45.29c-0.75,4.31-1.98,33.68-1.98,33.68c4.93,3.01,8.01,3.29,12.59,4.38
                        c4.59,1.1,10.47,0.68,12.8,0.75s15.54-0.75,15.54-0.75c-0.14-2.53-0.62-5.34-0.62-8.7c0-3.35,3.56-15.13,3.83-19.58
                        s-0.75-6.44-0.96-7.53c-0.21-1.1-0.14-3.15-0.41-3.9s0.07-0.82,0.07-1.78s-4.31-4.24-5.27-5.2s-4.79-3.83-6.16-3.83
                        s-1.1,0.48-1.3,0c-0.21-0.48-6.37-2.33-6.98-2.46c-0.62-0.14-2.4-0.34-3.35-0.14c-0.96,0.21-2.4-0.55-3.7-0.21
                        s-5.75,2.12-7.46,2.46s-4.86,0.14-4.86,0.14C134.15,38.92,132.78,40.98,132.03,45.29"/>
                        <path   d="M81.86,41.66c0,6.3-1.64,11.78-1.64,11.78c2.74,1.23,17.25,10.13,20.12,11.5
                        c2.88,1.37,20.36,9.45,20.36,9.45c-0.1-0.79,0.72-16.4,1.2-23.42c0.48-7.02,2.91-12.7,3.29-16.81s-1.06-9.55-1.23-9.89
                        c-0.17-0.34-1.64-1.64-2.05-1.57s-1.06-0.62-1.37-0.65s-0.92-0.24-1.03-0.48c-0.1-0.24-0.58-0.89-0.99-0.68
                        c-0.41,0.21-6.4-0.62-8.18-0.79s-3.97-0.89-6.85-1.06s-3.46-0.14-7.5,0.27s-7.12,0.72-9.65,2.05s-3.18,4.21-3.7,4.45
                        c-0.51,0.24-1.16,0.03-1.16,0.03C81.92,31.53,81.86,35.36,81.86,41.66"/>
                        <path  d="M42.25,34C48,38.47,72.09,49.43,72.09,49.43c1.28-3.1,1.1-13.05,1.19-14.79c0.09-1.73-0.27-8.49-0.64-9.95
                        c-0.37-1.46,0.27-3.19,0-4.11c-0.27-0.91-3.83-4.2-4.38-4.75s-5.84-2.74-7.21-2.83s-11.41,1.46-13.87,1.55s-11.5,0.18-12.41-0.18
                        c-0.91-0.37-2.37-0.46-3.29-0.46c-0.91,0-1.37,0.09-2.28,0.27s-2.74,0-3.56,0.36s-2.46,1.1-3.38,1.19
                        c-0.91,0.09-2.55-0.09-2.55-0.09C26.91,24.23,36.5,29.52,42.25,34"/>
                    </g>
                </g>
                <g style={{fill: colors[1]}}>
                    <path className="st0" d="M61.12,126.43l-11.18-0.12l-1.32,1.32c-0.25,8.29-0.31,14.51-0.31,18.84c0,11.12-2.51,16.77-7.28,16.77
                    c-3.7,0-6.65-3.58-6.65-7.98c0-1.32,0.44-4.21,1.32-8.79c0.75-4.77,0.94-11.99,0.44-21.67c-7.35-1.07-10.61-3.39-10.61-7.28
                    c0-3.14,1.44-4.27,5.78-4.27c1.76,0,3.52,0.13,5.15,0.25l21.97,1.51c0,0,4.52,1.63,5.34,4.15
                    C64.76,122.04,61.12,126.43,61.12,126.43"/>
                    <path className="st0" d="M86.35,189.91c-7.72-1.63-11.87-4.58-13.31-9.48l-6.15-20.66l-3.64-11.37l-2.13-10.11
                    c0,0,2.64-4.65,5.65-5.65c2.07-0.69,5.46,0,5.46,0c5.4,5.21,6.4,6.97,8.54,14.13c1.13,3.96,2.45,8.54,3.96,13.75
                    c3.45-5.97,6.09-10.55,7.6-13.75c4.4-8.98,5.9-10.3,12.43-10.3c1.63,0,3.45-0.88,4.9,0.31c1.44,1.19,1.38,4.58,1.38,4.58
                    C99.85,168.81,97.53,173.34,86.35,189.91"/>
                    <path className="st0" d="M152.57,150.61l-4.33,17.02l-3.45,16.2c-0.13,0.57-1.44,1.19-3.52,1.95c-1.32,0.44-2.51,0.69-3.58,0.69
                    c-3.7,0-5.21-2.07-7.97-10.68c-0.63-1.95-1.82-4.9-3.77-8.48h-1.13c0.25,1.51,0.38,2.89,0.38,4.46c0,5.97-0.69,7.16-6.03,11.3
                    c-4.33-1.45-5.09-1.95-6.84-5.09l-1.19-2.07h0.88c0.88-4.71,1.32-8.16,1.38-10.61c0.38-9.42,0.82-15.01,1.26-16.52
                    c0.19-0.75,1.19-2.51,2.7-5.02c9.23,0,11.99,2.2,19.59,16.14c1.19-9.8,4.58-15.26,9.35-15.26c2.26,0,4.77,1.32,5.97,3.26
                    c0.31,0.57,0.44,1.19,0.44,1.76C152.69,149.98,152.69,150.3,152.57,150.61"/>
                    <path className="st0" d="M176.62,200c-14.19,0-22.35-9.04-22.35-24.49c0-17.65,7.97-28.64,20.59-28.64c3.08,0,7.47,2.2,6.09,4.9
                    c-0.44,0.88-1.76,1.76-1.76,1.76s0.06,1.26,0,2.07c-0.38,6.78-10.3,8.16-10.3,14.13c0,9.67,1.51,12.18,8.35,14.95
                    c4.02-2.45,7.41-3.71,9.92-3.71c4.65,0,9.1,4.46,9.1,9.11C196.27,196.11,188.55,200,176.62,200"/>
                    <path className="st0" d="M218.35,192.32c-14.19,0-22.35-9.04-22.35-24.49c0-17.65,7.97-28.64,20.59-28.64c3.08,0,7.47,2.2,6.09,4.9
                    c-0.44,0.88-1.76,1.76-1.76,1.76s0.06,1.26,0,2.07c-0.38,6.78-10.3,8.16-10.3,14.13c0,9.67,1.51,12.18,8.35,14.95
                    c4.02-2.45,7.41-3.71,9.92-3.71c4.65,0,9.1,4.46,9.1,9.11C238,188.43,230.28,192.32,218.35,192.32"/>
                    <path className="st0" d="M276.61,137.17l-4.33,17.02l-3.45,16.2c-0.13,0.57-1.44,1.19-3.52,1.95c-1.32,0.44-2.51,0.69-3.58,0.69
                    c-3.7,0-5.21-2.07-7.97-10.68c-0.63-1.95-1.82-4.9-3.77-8.48h-1.13c0.25,1.51,0.38,2.89,0.38,4.46c0,5.97-0.69,7.16-6.03,11.3
                    c-4.33-1.45-5.09-1.95-6.84-5.09l-1.19-2.07h0.88c0.88-4.71,1.32-8.16,1.38-10.61c0.38-9.42,0.82-15.01,1.26-16.52
                    c0.19-0.75,1.19-2.51,2.7-5.02c9.23,0,11.99,2.2,19.59,16.14c1.19-9.8,4.58-15.26,9.35-15.26c2.26,0,4.77,1.32,5.97,3.26
                    c0.31,0.57,0.44,1.19,0.44,1.76C276.73,136.54,276.73,136.86,276.61,137.17"/>
                </g>
            </svg>
        </Logo>
    )
}
