import React from 'react';
import styled from 'styled-components';

export const Footer = styled.div`
    position: fixed;
    bottom: 0;
    color: white;
    font-family: sans-serif;
    text-align: center;
    height: 60px;
    width: 100%;
    
    @media (max-width: 768px) {
        font-size: 0.7rem;
    }
`;