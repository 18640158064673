import React from 'react';
import styled from 'styled-components';

export const Logo = styled.div`
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: sans-serif;
    cursor: pointer;
`;